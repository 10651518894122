document.addEventListener("turbolinks:load", function () {
  function getSelect2Params(url, formatResults = (value) => ({id: value.id, text: value.name}), dropdownParent = null) {
    return {
      theme: "bootstrap4",
      allowClear: true,
      width: "100%",
      minimumInputLength: 2, // Минимальная длина ввода
      dataType: "json",
      placeholder: "Выберите из списка",
      dropdownParent: dropdownParent,
      ajax: {
        url: url, // Динамический URL
        delay: 250,
        data: function (params) {
          return {search_name: params.term}; // Параметр поиска
        },
        processResults: function (data) {
          return {
            results: $.map(data, formatResults), // Используем переданный callback для форматирования
          };
        },
        cache: true, // Кеширование запросов
      },
    };
  };

  if ($(".select2-waybill").length > 0) {
    $(".select2-waybill").select2(getSelect2Params('/manager/waybills/search'));
  }

  if ($(".select2-contractor.payer").length > 0) {
    $(".select2-contractor").select2(getSelect2Params('/contractors/search?is_payer=true'));
  } else if ($(".select2-contractor").length > 0) {
    $(".select2-contractor").select2(getSelect2Params('/contractors/search'));
  }

  if ($(".select2-contractor-waybill").length > 0) {
    $(".select2-contractor-waybill").select2(getSelect2Params('/contractors/search', undefined, $('#modalExportWaybills')));
  }

  if ($(".select2-contractor-modal").length > 0) {
    $(".select2-contractor-modal").select2(getSelect2Params('/contractors/search?is_payer=true', undefined, $('#filter')));
  }

  if ($(".select2-money-incoming").length > 0) {
    $(".select2-money-incoming").select2(getSelect2Params('/manager/money_incomings/search'));
  }

})


