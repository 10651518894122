import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

document.addEventListener("turbolinks:load", function () {
  const lightbox = GLightbox({
    touchNavigation: true, loop: true, zoomable: true, moreText: "Подробнее", closeText: "Закрыть",
  });

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  // Функция для изменения классов в зависимости от разрешения экрана
  function updateClassesForMobile() {
    // Проверка ширины экрана, например, для мобильных устройств считаем ширину меньше 768px
    var largeFilter = document.getElementById("large-filter");
    // Получаем всех детей 'large-filter' с классом 'filter_menu_item'
    var filterMenuItems;

    if (largeFilter) {
      var filterMenuItems = largeFilter.querySelectorAll(".filter_menu_item");
    }

    if (largeFilter != null && filterMenuItems != null) {
      if (window.innerWidth < 768) {
        // Находим элемент с id 'large-filter' и удаляем класс 'd-none', если он есть
        if (largeFilter.classList.contains("d-none")) {
          largeFilter.classList.remove("d-none");
        }
        // Перебираем эти элементы
        filterMenuItems.forEach(function (item) {
          // Проверяем, есть ли внутри элементы с классами 'badge-success' или 'badge-danger'
          const hasBadgeSuccess = item.querySelector(".badge-primary");
          const hasBadgeDanger = item.querySelector(".badge-danger");

          if (!hasBadgeSuccess && !hasBadgeDanger) {
            // Если таких элементов нет, добавляем класс 'd-none'
            item.classList.add("d-none");
          }
        });
      } else {
        if (!largeFilter.classList.contains("d-none")) {
          // largeFilter.classList.add('d-none');
        }
        filterMenuItems.forEach(function (item) {
          // Проверяем, есть ли внутри элементы с классами 'badge-success' или 'badge-danger'
          const hasBadgeSuccess = item.querySelector(".badge-primary");
          const hasBadgeDanger = item.querySelector(".badge-danger");

          if (!hasBadgeSuccess && !hasBadgeDanger) {
            // Если таких элементов нет, добавляем класс 'd-none'
            item.classList.remove("d-none");
          }
        });
      }
    }
  }

  // Вызываем функцию при первой загрузке страницы
  updateClassesForMobile();

  function moveElements({
                          maxWidth = 992,
                          targetId = "first_menu",
                          sourceId = "second_menu",
                          menuItemClass = "second_menu_item",
                        } = {}) {
    const target = document.getElementById(targetId);
    const source = document.getElementById(sourceId);

    if (!source || !target) return; // Проверяем существование элементов

    const secondMenuItems = document.querySelectorAll(`.${menuItemClass}`);

    if (window.innerWidth < maxWidth) {
      // Перемещаем все элементы из source в target
      const virtualContainer = document.createDocumentFragment();

      // Перебираем всех детей source
      Array.from(source.childNodes).forEach((child) => {
        if (child.nodeType === Node.ELEMENT_NODE) { // Убедимся, что это элемент, а не текст
          child.classList.add(menuItemClass);
          virtualContainer.appendChild(child); // Перемещаем элемент с его дочерними
        }
      });

      target.prepend(virtualContainer); // Вставляем в target
    } else {
      // Возвращаем элементы с классом menuItemClass обратно в source
      secondMenuItems.forEach((element) => {
        element.classList.remove(menuItemClass); // Убираем временный класс
        source.appendChild(element); // Возвращаем в source
      });
    }
  }

  // Вызов функции при первой загрузке страницы
  moveElements({
    maxWidth: 992, targetId: "first_menu", sourceId: "source_menu_2", menuItemClass: "second_menu_item_2",
  });
  moveElements({
    maxWidth: 992, targetId: "first_menu", sourceId: "second_menu", menuItemClass: "second_menu_item",
  });

  // Вызов функции при изменении размера окна
  window.onresize = () => {
    moveElements({
      maxWidth: 992, targetId: "first_menu", sourceId: "source_menu_2", menuItemClass: "second_menu_item_2",
    });
    moveElements({
      maxWidth: 992, targetId: "first_menu", sourceId: "second_menu", menuItemClass: "second_menu_item",
    });
    updateClassesForMobile();
  };

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  });

  $(function () {
    $('[data-toggle="popover"]').popover()
  });

  $("#mygallery").justifiedGallery({
    rowHeight: 300, maxRowHeight: 300, lastRow: "nojustify", margins: 3,
  });

  $('a[data-toggle="tab"]').on("show.bs.tab", function (e) {
    localStorage.setItem("activeTab", $(e.target).attr("href"));
  });

  $('a[data-tab="tab"]').on("click", function (e) {
    let href = $(e.target).attr("href");
    let hashIndex = href.indexOf("#");
    if (hashIndex >= 0) {
      let anchor = href.substring(hashIndex);
      localStorage.setItem("activeTab", anchor);
    }
  });

  let activeTab = localStorage.getItem('activeTab');

  if (activeTab) {
    $('#nav-tab a[href="' + activeTab + '"]').tab("show");
  }

  $(function () {
    $('.contract_tooltip').tooltip({
      'delay': {show: 0, hide: 1000}
    })
  })

  let maskOptions = {
    mask: '+0(000)000-00-00[00000]'
  };
  const phones = document.querySelectorAll('.phone')

  phones.forEach((phone) => {
    IMask(phone, maskOptions);
  })

  $(function () {
    $('.fotorama').fotorama();
  });

  $(function () {
    if ($("html").hasClass("fullscreen")) {
      $("html").removeClass("fullscreen");
    }
  });

  $('.select2-container').remove();

  $(".select2-color_item").select2({
    theme: "bootstrap4",
    allowClear: true,
    width: "100%",
    minimumInputLength: 2,
    dataType: "json",
    placeholder: "Выберите значение",
    ajax: {
      url: "/color_items/search", delay: 250, data: function (params) {
        return {search_name: params.term};
      }, processResults: function (data, params) {
        return {
          results: $.map(data, function (value, index) {
            return {id: value.id, text: value.name_with_id};
          }),
        };
        cache: true;
      },
    },
  });

  $(".select2-invoice").select2({
    theme: "bootstrap4",
    allowClear: true,
    width: "100%",
    minimumInputLength: 2,
    dataType: "json",
    placeholder: "Выберите значение",
    ajax: {
      url: "/manager/invoices/search", delay: 250, data: function (params) {
        return {search_name: params.term};
      }, processResults: function (data, params) {
        return {
          results: $.map(data, function (value, index) {
            return {id: value.id, text: value.to_label};
          }),
        };
        cache: true;
      },
    },
  });

  $("select.select2").select2({
    theme: "bootstrap4", allowClear: true, placeholder: "Выберите значение", width: '100%'
  });

  $(".select2-contract").select2({
    theme: "bootstrap4",
    allowClear: true,
    width: "100%",
    minimumInputLength: 2,
    dataType: "json",
    placeholder: "Выберите значение",
    ajax: {
      url: "/manager/contracts/search", delay: 250, data: function (params) {
        return {search_name: params.term};
      }, processResults: function (data, params) {
        return {
          results: $.map(data, function (value, index) {
            return {id: value.id, text: value.name};
          }),
        };
        cache: true;
      },
    },
  });

  $("#search_box_items #search").focus();

  $('#search_box_items #search').select();

  $('#dupModal').on('shown.bs.modal', function (e) {
    $('#box_times').focus();
    $('#box_times').select();
  })

  $('#contractor_id_select').on('change.select2', function (e) {
    document.querySelector('#search_by_contractor').submit();
  });

  $("#collapseMenu").on("shown.bs.collapse", function () {
    $("#header-fixed").addClass("shadow-bottom");
  });

  $("#collapseMenu").on("hidden.bs.collapse", function () {
    $("#header-fixed").removeClass("shadow-bottom");
  });

  // Эффект вспышки для Корзины
  (function () {
    // Получаем элемент, за которым нужно наблюдать
    const targetNode = document.getElementById("cart");

    // Проверяем, существует ли элемент
    if (!targetNode) {
      console.error('Элемент с id "myElement" не найден.');
      return;
    }

    // Настройки для наблюдателя (какие именно изменения следует отслеживать)
    const config = {
      childList: true, // отслеживание добавления или удаления дочерних элементов
      attributes: false, // отслеживание изменений атрибутов
      characterData: true, // отслеживание изменений текстового содержимого
      subtree: true, // отслеживание изменений во всем поддереве элемента
    };

    // Функция, которая будет вызываться при каждом изменении
    const callback = function (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" || mutation.type === "characterData") {
          console.log("Обнаружено изменение содержимого!");
          targetNode.classList.add("flash");

          // Удаляем класс после завершения анимации
          setTimeout(() => {
            targetNode.classList.remove("flash");
          }, 500); // Длительность анимации должна совпадать с CSS
        }
      }
    };

    // Создание экземпляра MutationObserver с передачей функции обратного вызова
    const observer = new MutationObserver(callback);

    // Начинаем наблюдение за настроенными изменениями в целевом элементе
    observer.observe(targetNode, config);

    // Позже, если наблюдение больше не нужно, можно отменить его
    // observer.disconnect();
  })();
});
