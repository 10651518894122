document.addEventListener("turbolinks:load", function () {
  const token = "bedccc811d83be765ffad127e2c43b7528c37f50";
  let $city = $(".address");

  // город и населенный пункт
  $city.suggestions({
    token: token, type: "ADDRESS", hint: false, bounds: "city"
  });

  let $address_object = $(".address_object");

  $address_object.suggestions({
    token: token, type: "ADDRESS",
    onSelect: function (suggestion) {

    },
  });

  $(".contractor_address_legal_company").suggestions({
    token: token, type: "ADDRESS",
    onSelect: function (suggestion) {
      fillAddressData(suggestion.data);
    }
  });

  let $bank = $(".bank_suggestions");

  $bank.suggestions({
    token: token, type: "BANK", /* Вызывается, когда пользователь выбирает одну из подсказок */
    onSelect: function (suggestion) {
      fillBankData(suggestion.data);
    }
  });

  function getAddressValue(query) {
    var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"; // Используем HTTPS

    var options = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({query: query})
    };

    return fetch(url, options) // ✅ Возвращаем промис
      .then(response => response.json()) // ✅ Парсим JSON
      .then(result => {
        console.log(result); // Посмотрим полный ответ
        return result.suggestions?.[0]?.value || "Адрес не найден"; // ✅ Возвращаем значение
      })
      .catch(error => {
        console.error("Ошибка:", error);
        return "Ошибка запроса"; // ✅ Возвращаем ошибку, если запрос сломался
      });
  }

  function fillContractorData(data) {
    // Название организации
    $('.contractor_name').val(data.name.short_with_opf);
    $('.contractor_inn').val(data.inn);
    // ОГРН
    $('.contractor_ogrn').val(data.ogrn);
    // КПП
    $('.contractor_kpp').val(data.kpp);
    // Адрес
    if (data.address && data.address.value) {
      if ($('.contractor_address_fact_company').val() == "") {
        getAddressValue(concatenateAddressData(data.address.data)).then(address => {
          $('.contractor_address_fact_company').val(address);
        });
      }
      if ($('.contractor_address_legal_company').val() == "") {
        getAddressValue(concatenateAddressData(data.address.data)).then(address => {
          $('.contractor_address_legal_company').val(address);
        });
      }
    }
  }

  function fillBankData(data) {
    $('.contractor_bank_name').val(data.name.payment);
    $('.contractor_bik').val(data.bic);
    $('.contractor_bank_address').val(data.address.value);
    $('.contractor_corr_acc').val(data.correspondent_account);
    $('.contractor_swift').val(data.swift);
  }

  // Заполняем jsonb для отправки в Диадок
  function fillAddressData(data) {
    const addressData = getAddressData(data);
    $('#contractor_address_data_index').val(addressData.postal_code);
    $('#contractor_address_data_region').val(addressData.region);
    $('#contractor_address_data_area').val(addressData.area_with_type);
    $('#contractor_address_data_street').val(addressData.street);
    $('#contractor_address_data_house').val(addressData.house);
    $('#contractor_address_data_city').val(addressData.city);
    $('#contractor_address_data_settlement').val(addressData.settlement_with_type);
    $('#contractor_address_data_block').val(addressData.block);
    $('#contractor_address_data_flat').val(addressData.flat);
  }

  function getAddressData(data) {
    return {
      postal_code: data.postal_code, // Почтовый индекс
      region: data.region, // Регион
      area_with_type: data.area_with_type, // Район
      street: data.street_with_type, // Улица
      house: data.house, // Дом
      city: data.city_with_type, // Город
      settlement_with_type: data.settlement_with_type, // Населенный пункт
      block: data.block, // Корпус
      flat: data.flat // Квартира
    };
  }

  function concatenateAddressData(data) {
    let addressParts = [];

    if (data.postal_code) {
      addressParts.push(data.postal_code);
    }
    if (data.region) {
      addressParts.push(data.region);
    }
    if (data.area_with_type) {
      addressParts.push(data.area_with_type);
    }
    if (data.city_with_type) {
      addressParts.push(data.city_with_type);
    }
    if (data.street_with_type) {
      addressParts.push(data.street_with_type);
    }
    if (data.house) {
      addressParts.push(data.house);
    }
    if (data.settlement_with_type) {
      addressParts.push(data.settlement_with_type);
    }
    if (data.block) {
      addressParts.push(data.block);
    }
    if (data.flat) {
      addressParts.push(data.flat);
    }

    return addressParts.join(', ');
  }

  let $company_object = $(".company_object");

  $company_object.suggestions({
    token: token, type: "PARTY",
    onSelect: function (suggestion) {
      fillContractorData(suggestion.data);
      // fillAddressData(suggestion.data);
    }
  });

  let $street = $(".street");

  // город и населенный пункт
  $street.suggestions({
    token: token, type: "ADDRESS", hint: false, bounds: "city-flat"
  });
});