document.addEventListener("turbolinks:load", function() {
    var headerFixed = document.querySelector('#header-fixed');
    var contact_info = document.getElementById('contact_info');
    var main_logo = document.getElementById('main_logo');

    function debounce(func, wait) {
        var timeout;
        return function() {
            clearTimeout(timeout);
            timeout = setTimeout(func, wait);
        };
    }

    // Реализуем функцию throttle
    function throttle(func, limit) {
        let lastFunc;
        let lastRan;
        return function(...args) {
            const context = this;
            if (!lastRan) {
                func.apply(context, args);
                lastRan = Date.now();
            } else {
                clearTimeout(lastFunc);
                lastFunc = setTimeout(function() {
                    if ((Date.now() - lastRan) >= limit) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    }
                }, limit - (Date.now() - lastRan));
            }
        };
    }

    // Обработчик завершения перехода на элементе headerFixed и его внутренних элементах
    function addTransitionEndListener(element) {
        element.addEventListener('transitionend', function(event) {
            if (['height', 'padding', 'margin', 'width'].includes(event.propertyName)) {
                updateMarginTop();
            }
        });
    }

    function updateMarginTop() {
        if (!headerFixed) return;
        var headerHeight = headerFixed.offsetHeight;
        $('#main-content').css("margin-top", headerHeight);
    }

    function handleScroll() {
        if (!headerFixed) return;

        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // Добавление/удаление тени в зависимости от положения прокрутки
        if (scrollTop > 50) {
            headerFixed.classList.add('shadow-bottom');
        } else {
            headerFixed.classList.remove('shadow-bottom');
        }

        // Обработка изменения размеров и видимости элементов при прокрутке
        if (contact_info && main_logo) {
            if (scrollTop > 10) {
                // Прокрутка вниз
                contact_info.classList.add('scroll_hidden');
                main_logo.classList.add('main_logo_img_mini');
            } else {
                // Прокрутка вверх
                contact_info.classList.remove('scroll_hidden');
                main_logo.classList.remove('main_logo_img_mini');
            }
        }

        // addTransitionEndListener(headerFixed);

        // Обновление margin-top при прокрутке
        updateMarginTop();
    }

    window.addEventListener('scroll', debounce(function(){
        handleScroll();
    }, 50));

    const throttledUpdateMarginTop = throttle(updateMarginTop, 500); // Ограничение - не чаще чем раз в 500 мс

    // Создаем новый наблюдатель за изменением размера
    const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
            throttledUpdateMarginTop();
        }
    });

    // Начинаем наблюдение за элементом
    if (headerFixed instanceof Element) {
        resizeObserver.observe(headerFixed);
    }

    // Первоначальное обновление margin-top
    throttledUpdateMarginTop();

    $(function () {
        $(window).resize(function() {
            $('#main-content').css("padding-bottom", $("#resource_menu").height());
        }).resize();
    });

    let lastScrollTop = 0;

    function handleFixedMenuScroll() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const fixedMenu = document.getElementById('large-filter');

        // Выходим из метода, если элемент не найден
        if (!fixedMenu) {
            return;
        }


        if (scrollTop > 200) {
            fixedMenu.classList.add('d-none'); // Добавьте класс для скрытия
        } else if (scrollTop <= 10) {
            fixedMenu.classList.remove('d-none'); // Удалите класс, если у верхушки страницы
        }

        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Ожидание нового значения
    }

    // Добавьте обработчик для функции
    window.addEventListener('scroll', debounce(handleFixedMenuScroll, 50));
})